var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-menu',{attrs:{"rounded":"","offset-y":"","close-on-content-click":false,"min-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{staticClass:"rounded-circle",attrs:{"color":"red","content":_vm.countNoRead,"offset-x":"20","offset-y":"15","value":_vm.countNoRead != 0,"bordered":"","top":""}},[_c('v-btn',_vm._g({attrs:{"disabled":_vm.data.length == 0,"color":"white","icon":""}},on),[_c('v-icon',[_vm._v("mdi-reminder")])],1)],1)]}}])},[_c('v-sheet',{staticClass:"d-flex flex-column pa-3",attrs:{"rounded":""}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Reminders")]),_c('v-spacer')],1),_c('v-list',[_c('v-list-item-group',{staticClass:"listnotify",attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.data),function(item){return _c('v-list-item',{key:item.uuid},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex"},[_c('div',{class:item.status == 'PENDING'
                        ? 'font-weight-bold'
                        : 'text--secondary'},[_vm._v(" "+_vm._s(item.content)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","x-small":"","icon":""},on:{"click":function($event){return _vm.toDelteReminder(item.uuid)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Reminder")])])],1),_c('v-list-item-subtitle',{class:item.status == 'PENDING'
                      ? 'text-right orange--text'
                      : 'text-right green--text'},[_vm._v(_vm._s(item.status == "PENDING" ? "Pending for: " + _vm.getDate(item.executeAt) : "Executed on: " + _vm.getDate(item.executeAt)))]),_c('v-list-item-subtitle',{staticClass:"text-right blue--text"},[_vm._v(_vm._s("Created at: " + _vm.getTime(item.createdAt)))])],1)],1)}),1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"400px","transition":"dialog-transition"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"error white--text"},[_vm._v(" Delete Reminder ")]),_c('v-card-text',{staticClass:"mt-4 text-center"},[_c('h4',[_vm._v("Are you sure you want delete this reminder?")])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"text":""},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.loading,"disabled":_vm.loading,"text":""},on:{"click":_vm.confirmDelte}},[_vm._v("Delete")])],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }